import React, { forwardRef, useRef } from "react";
import { PopupButton } from "@typeform/embed-react";
import { motion, useInView } from "framer-motion";

import Dots from "../../assets/graphics/Dots_1.svg";
import Lollipop from "../../assets/graphics/Lollipop.svg";

const Registration = forwardRef<HTMLDivElement>((_, ref) => {
  const imageRef = useRef(null);
  const isInView = useInView(imageRef, { once: true });

  let HeaderText: string[] = "CONJURE-UP-YOUR-BEST-CODE".split("");

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.075,
      },
    },
  };

  const items = {
    hidden: {
      y: "50%",
      opacity: 0,
      color: "rgb(102 102 204)",
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
    },
    visible: {
      y: 0,
      color: "rgb(250 238 212)",
      opacity: 1,
      transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
    },
  };

  return (
    <div className="my-24" ref={ref}>
      <div className="sm:flex lg:grid lg:grid-cols-3 lg:grid-rows-1 py-2 my-6">
        <img
          className="hidden sm:block sm:self-start lg:justify-self-end  pointer-events-none"
          alt=""
          src={Dots}
        />
        <div className="col-start-2 p-2 m-2 grid auto-rows-auto grid-cols-1">
          <p className="font-outfit justify-self-start">
            UNT Discovery Park in Denton, TX
          </p>
          <motion.h2
            style={{ zIndex: -1 }}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            variants={container}
            className="flex justify-center items-center text-2xl sm:text-3xl md:text-4xl lg:text-4xl 2xl:text-5xl 3xl:text-6xl font-semibold font-outfit justify-self-center text-center"
          >
            {HeaderText.map((item, index) => {
              return item === "-" ? (
                <motion.div
                  key={index}
                  variants={items}
                  className="px-1 sm:px-2"
                >
                  {" "}
                </motion.div>
              ) : (
                <motion.div key={index} variants={items}>
                  {item}
                </motion.div>
              );
            })}
          </motion.h2>
          <p className="text-right font-outfit font-medium">
            This October, join hundreds of hackers from all around the state for
            an in-person hackathon like no other.
          </p>
          <PopupButton
            id="yDEEppFh"
            className="bg-buttonDefault hover:bg-buttonClicked text-White hover:text-DarkBlue hover:scale-110 active:scale-90 bg-no-repeat bg-contain bg-center justify-self-end font-outfit font-semibold my-4 py-2 px-8 lg:px-16 2xl:px-8"
          >
            Register
          </PopupButton>
        </div>
        <img
          className=" -z-10 sm:block sm:self-end lg:justify-self-start scale-x-[-0.5] scale-y-[0.5]  pointer-events-none"
          alt=""
          src={Lollipop}
        />
      </div>
      <div
        className="grid grid-cols-2 lg:grid-cols-4 auto-rows-auto items-end justify-items-center py-4 my-2"
        ref={imageRef}
      ></div>
    </div>
  );
});

export default Registration;
