import React, { useEffect, useState } from "react";
import Star from "../../assets/graphics/Star_Purple.svg";
import Ghost from "../../component/Ghost";

interface TimeBlockProps {
  time: number;
  label: string;
}

const TimeBlock: React.FC<TimeBlockProps> = ({ time, label }) => {
  return (
    <div className="grid grid-cols-1 auto-rows-auto justify-items-center px-4 lg:px-8 md:w-32 lg:w-40 xl:w-48">
      <span className="font-outfit font-semibold text-6xl md:text-7xl lg:text-8xl xl:text-9xl">
        {time}
      </span>
      <p className="italic md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl font-outfit font-semibold uppercase">
        {label}
      </p>
    </div>
  );
};

export default function Hero() {
  let [days, setDays] = useState(0);
  let [hours, setHours] = useState(0);
  let [minutes, setMinutes] = useState(0);
  let [seconds, setSeconds] = useState(0);

  const countDownDate = new Date("Oct 26, 2024 00:00:00").getTime();
  useEffect(() => {
    const intervalId = setInterval(() => {
      let now = new Date().getTime();
      let distance = countDownDate - now;
      if (distance < 0) {
        clearInterval(intervalId);
        return;
      }
      setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
    }, 1000);
  },[countDownDate]);

  return (
    <div className="h-5/6 flex flex-wrap justify-center container mx-auto relative -z-10">
      <div className="text-White mt-20 md:mt-0 w-full sm:w-auto flex flex-col items-center content-center justify-self-center self-center py-4 px-4 sm:px-0">
        <p className="py-6 md:py-9 text-lg xl:text-xl 2xl:text-2xl text-center font-outfit font-medium">
          October 26-27, 2024
        </p>
        <div className="grid grid-cols-4 grid-rows-1 w-full justify-center py-2 divide-x-2 rounded">
          <TimeBlock time={days} label="Days" />
          <TimeBlock time={hours} label="Hours" />
          <TimeBlock time={minutes} label="Minutes" />
          <TimeBlock time={seconds} label="Seconds" />
        </div>
      </div>
      <div className="mt-12 md:mt-32 w-full flex justify-center">
        <img
          className="py-6 md:py-9 justify-self-center self-end"
          alt=""
          src={Star}
        />
      </div>
      <div className="text-White absolute top-12 right-12 md:right-32">
        <Ghost />
      </div>
    </div>
  );
}
