import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Ghost_3 from "../../assets/graphics/Ghost_3.svg";

export default function Hero() {
  return (
    <header className="text-White text-center py-12">
      <motion.img
        className="mx-auto my-auto pointer-events-none animate-wiggle opacity-50"
        alt=""
        src={Ghost_3}
      />
      <h2 className="text-7xl sm:text-8xl md:text-9xl font-outfit font-semibold">404 :(</h2>
      <h3 className="text-5xl sm:text-6xl md:text-7xl font-outfit font-semibold">
        PAGE NOT FOUND
      </h3>
      <p className="font-outfit font-medium text-[20px] leading-[30px] text-xl">
        Return To{" "}
        <Link to="/" className="underline">
          HackUNT
        </Link>
      </p>

      <p className="font-outfit font-light text-base leading-[30px] mt-48">
        If you think this is an error, email{" "}
        <a
          href="mailto:hackuntfaq@gmail.com"
          className="underline text-Yellow"
        >
          hackuntfaq@gmail.com
        </a>{" "}
        to address this issue.
      </p>
    </header>
  );
}
