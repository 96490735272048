import React from "react";
import {ReactComponent as GhostOrange} from "../../assets/graphics/Ghost_Orange.svg";
import Pile from "../../assets/graphics/PileOfCandy.svg";

const StatsView: React.FC = () => {
    return (
        <div className="container mx-auto flex flex-wrap justify-around md:justify-evenly xl:justify-around md:-mt-32">
            <div
                className="relative w-full md:w-auto flex items-center justify-start xl:justify-center -my-2 mx-4 md:m-12 md:-ml-12 md:mt-32 xl:mt-12">
                <div className="h-full w-64 flex items-center justify-center">
                    <GhostOrange className="absolute h-36 w-36 animate-circle opacity-30 -z-10"/>
                    <div className="text-center p-4 -z-10">
                        <div className="font-outfit font-semibold text-8xl text-Blue">6</div>
                        <div className="font-outfit font-medium text-White italic">Years</div>
                    </div>
                </div>
            </div>
            <div className="block xl:hidden">&nbsp;</div>
            <div
                className="w-full md:w-auto flex items-center justify-end xl:justify-center -my-2 mx-4 md:m-12 md:-ml-12 xl:mt-64 md:mt-32">
                <div className="text-center p-4">
                    <div className="font-outfit font-semibold text-8xl text-Blue">322+</div>
                    <div className="font-outfit font-medium text-White italic">
                        Projects Submitted
                    </div>
                </div>
            </div>
            <div className="hidden md:block xl:hidden">&nbsp;</div>
            <div className="hidden md:block xl:hidden">&nbsp;</div>
            <div className="hidden md:block w-full xl:hidden">&nbsp;</div>
            <div className="hidden md:block xl:hidden">&nbsp;</div>
            <div className="hidden md:block xl:hidden">&nbsp;</div>
            <div
                className="w-full md:w-auto flex items-center justify-start md:justify-end xl:justify-center -my-2 mx-4 md:m-12 md: xl:mt-24">
                <div className="text-center p-4">
                    <div className="font-outfit font-semibold text-8xl text-Blue">900+</div>
                    <div className="font-outfit font-medium text-White italic">Total Hackers</div>
                </div>
            </div>
            <div
                className="w-full md:w-auto flex items-center justify-end md:justify-end xl:justify-center -my-2 mx-4 md:m-12 md:ml-24 xl:mt-96">
                <div className="text-center p-4">
                    <div className="font-outfit font-semibold text-8xl text-Blue flex items-center">
                        <div className="h-16 w-12 -mr-1"/>
                        $100k+
                    </div>
                    <div className="font-outfit font-semibold text-White italic">
                        In Prizes Awarded
                    </div>
                    <img className="absolute -z-10 w-72 justify-center opacity-30 -translate-y-16" src={Pile} alt=""/>

                </div>

            </div>

        </div>
    );
};

export default StatsView;
