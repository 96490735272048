import React from "react";
import Cbre from "../assets/logos/cbre-logo.svg";
import Fidelity from "../assets/logos/fidelity-logo.svg";
import GoldmanSachs from "../assets/logos/goldman-sachs-logo.svg";
import RESPEC from "../assets/logos/respec-logo.svg";
import StandOutStickers from "../assets/logos/stand-out-stickers-logo.svg";
import UntRobotics from "../assets/logos/unt-robotics-logo.svg";

const SponsorImages: React.FC = () => {
  const sponsorImages = [
    {
      imageUrl: Cbre,
    },
    {
      imageUrl: Fidelity,
    },
    {
      imageUrl: GoldmanSachs,
    },
    {
      imageUrl: RESPEC,
    },
    {
      imageUrl: StandOutStickers,
      link: "https://www.standoutstickers.com/?utm_campaign=events-league-organizers-fall2023&utm_medium=email&utm_source=customerio-zoho_creator_-_standout_sticker_intro",
    },
    {
      imageUrl: UntRobotics,
    }
  ];

  return (
    <div className="2xl:py-20 2xl:px-60 mt-auto">
      <div className="grid grid-cols-3 gap-x-6 sm:gap-x-20 lg:gap-x-6 2xl:gap-x-16 gap-y-10 md:gap-y-0">
        {sponsorImages.map((image, index) => (
          <div key={index} className="flex items-center justify-center col-start-">
            {'link' in image ?
                <a href={image.link}   className="lg:max-h-80p 2xl:max-w-20rem">
                  <img
                      src={image.imageUrl}
                      alt={`Sponsor ${index + 1}`}
                      className="lg:max-h-60p lg:min-w-70p 2xl:max-w-20rem"
                  />
                </a> :
                <img
                    src={image.imageUrl}
                    alt={`Sponsor ${index + 1}`}
                    className="lg:max-h-60p lg:min-w-70p 2xl:max-w-20rem"
                />
            }
          </div>
        ))}
        {/*        <h2 className="text-center text-2xl italic col-span-3">
          More Coming Soon
        </h2>*/}
      </div>
    </div>
  );
};

export default SponsorImages;
