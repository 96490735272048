import React from "react";
import Sparkle from "../../assets/graphics/Sparkle.svg";

export default function Hero() {
  return (
    <div className="w-full 2xl:grid 2xl:grid-cols-3 2xl:auto-rows-auto">
      <div className="col-start-2 flex flex-col items-center pt-32 p-4 md:mx-8 lg:mx-16 xl:mx-32 2xl:mx-0 font-outfit font-medium gap-y-8 md:gap-y-18 text-White text-xl text-center">
        <div className="grid grid-cols-2 grid-rows-1 md:grid-cols-4 align-center items-end">
          <h2 className="col-start-1 row-start-1 col-span-2 md:col-span-4 md:col-end-5 text-5xl sm:text-6xl md:text-8xl lg:text-10xl font-semibold pb-4 font-outfit">
            Going Beyond
          </h2>
          <img
            className="-z-10 col-start-2 row-start-1 md:self-start md:col-start-4 md:rotate-180 w-20 md:w-40 h-auto self-begin 2xl:rotate-0 translate-x-16 -translate-y-20 md:translate-x-20 lg:translate-x-48 md:-translate-y-20 pointer-events-none"
            src={Sparkle}
            alt=""
          />
        </div>
        <div className="">
          HackUNT is a student-run, non-profit event hosted by the registered
          student organization Engineers United. Founded in 2017, organizers
          have strived to bring students together from all backgrounds to
          innovate and solve real-world problems.
        </div>
        <div className="">
          During a 24-hour hackathon, participants gather to develop highly
          innovative web, mobile and hardware applications in teams of 2 to 4
          people.
        </div>
        <div className="">
          Renowned companies and organizations of different industries provide
          contributions that fund the entirety of the HackUNT.
        </div>
      </div>
    </div>
  );
}
