import React from "react";
/*import ScheduleBlock, {
  ScheduleBlockProps,
} from "../../component/ScheduleBlock";*/
// event categories are core, event, workshop, meal
// id's should start at 0
//setting lengthMinutes to 0 makes it a single line instead of a block
// const saturdaySchedule: ScheduleBlockProps[] = []
/*const sundaySchedule: ScheduleBlockProps[] = [
  {
    id: 17,
    startTime: new Date(2023, 10, 11, 7, 0),
    lengthMinutes: 0,
    title: "Quiet Room (K110) Closes",
    category: "core",
  },
];*/

export default function Hero() {
  return (
    <header className="text-White">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-5xl sm:text-6xl md:text-8xl lg:text-10xl md:mt-10 pb-4 font-outfit font-semibold uppercase">
          Event Schedule
        </h2>
        <p className="text-xs md:text-sm lg:text-xl p-4 md:mx-8 lg:mx-16 2xl:mx-32 md:mt-14 md:mb-6 lg:mb-12 md:w-2/3 xl:w-2/5 text-center font-outfit font-light">
          For any general inquiries about HackUNT or your participation, please
          send your email to{" "}
          <a
            className="text-Yellow text-decoration-line: underline"
            href="mailto:hackuntfaq@gmail.com"
          >
            hackuntfaq@gmail.com
          </a>
        </p>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap space-between gap-8 font-outfit font-semibold px-1 md:px-16 xl:px-36">
        <div className="grid gap-4 w-full h-min">
          <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-misc lg:mb-10 text-Lavender md:text-left font-outfit font-medium text-center w-full">
            Saturday, October 26th
          </h2>
          {/*{saturdaySchedule.map((item) => (
            <ScheduleBlock key={item.id} {...item} />
          ))}*/}
          <h3 className="text-center md:text-justify">Coming Soon...</h3>
        </div>
        <div className="grid gap-4 w-full h-min">
          <h2 className="text-xl lg:text-2xl xl:text-3xl 2xl:text-misc lg:mb-10 text-Lavender md:text-left font-outfit font-medium text-center w-full">
            Sunday, October 27th
          </h2>
          {/*
          {sundaySchedule.map((item) => (
            <ScheduleBlock key={item.id} {...item} />
          ))}*/}
          <h3 className="text-center md:text-justify">Coming Soon...</h3>
        </div>
      </div>
    </header>
  );
}
